import React from 'react';
import { CallToAction } from '@bbc-account/id-components';
import url from 'url';
import PropTypes from 'prop-types';
import renderQueryString from '../../../../../shared/urls/renderQueryString';
import nmaExternalHash from '../../../../../shared/urls/nmaExternalHash';
import { IGNORE_VIEWS } from '../../../../../shared/data/analytics';
import { isIframeLayout } from '../../../../../shared/utilities/isIframeLayout';
import withEventTracking from '../../../withEventTracking';
import connectToState from '../../../connectToState';
import { useStateContext } from '../../../../modules/stateContext';

const OtpApplicationLinkContainer = props => {
    const {
        children,
        withExternalHash = props.external,
        withState = !props.external,
        href,
        target,
        userContext,
        createEventAttributes,
        ariaLabel,
        id,
        onClick,
    } = props;

    const { mvt = {} } = useStateContext();

    const userVariantName = mvt?.experiments?.acc_sireve_reg_email_verification;

    const shouldOpenInParentWindow =
        typeof userContext !== 'undefined' &&
        isIframeLayout(userContext.layout) &&
        target !== '_self';

    const externalHash = withExternalHash
        ? nmaExternalHash(userContext?.isNativeMobileApplication)
        : '';

    let statefulHref;

    if (typeof href === 'function') {
        const boundRenderQueryString = renderQueryString.bind(userContext);
        statefulHref = `${href(
            boundRenderQueryString,
            userContext
        )}${externalHash}`;
    } else {
        const queryString = withState
            ? renderQueryString.call(userContext)
            : '';

        statefulHref = `${href}${queryString}${externalHash}`;
    }

    if (shouldOpenInParentWindow) {
        statefulHref = statefulHref.replace(/[?&]layout=iframe/, '');
    }

    const eventAttributes = createEventAttributes
        ? createEventAttributes({
              type: 'link',
              result: url.parse(statefulHref).pathname,
              ignoreViews: IGNORE_VIEWS,
              personalisation:
                  userVariantName &&
                  JSON.stringify({
                      EXP: `sireve-mand-email-ver-1::${userVariantName}`,
                  }),
          })
        : {};

    return (
        <CallToAction
            ariaLabel={ariaLabel}
            target={target}
            variant="primary"
            id={id}
            eventAttributes={eventAttributes}
            onClick={onClick}
        >
            {children}
        </CallToAction>
    );
};

OtpApplicationLinkContainer.propTypes = {
    ariaLabel: PropTypes.string,
    children: PropTypes.node.isRequired,
    createEventAttributes: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object,
    external: PropTypes.bool,
    fullWidth: PropTypes.bool,
    method: PropTypes.oneOf(['get', 'post']),
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    target: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    withExternalHash: PropTypes.bool,
    withState: PropTypes.bool,
    userContext: PropTypes.shape({
        isNativeMobileApplication: PropTypes.bool,
        layout: PropTypes.string,
    }),
};

export { OtpApplicationLinkContainer };
export default connectToState(withEventTracking(OtpApplicationLinkContainer));

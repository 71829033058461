import React from 'react';
import { mapKeys } from 'lodash';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import {
    DEFAULT_SOURCE,
    ATTRIBUTES_MAP,
} from '../../../../shared/data/analytics';
import { resolvePageForEvent } from '../../../../shared/utilities/events';
import { useStateContext } from '../../../modules/stateContext';

const getContainer = pathname => pathname.split('/')[1];

const Track = ({
    children,
    container,
    'data-testid': dataTestId,
    name,
    result,
    type,
    source,
    ignoreViews,
    metadata,
}) => {
    const stringifiedMetadata = JSON.stringify(metadata);
    const { pathname } = useLocation();
    const { mvt = {}, featureToggles = {} } = useStateContext();

    const userVariantName = mvt?.experiments?.acc_sireve_reg_email_verification;

    const givenAttributes = {
        container: resolvePageForEvent(container || getContainer(pathname)),
        name,
        result,
        type,
        source: source || DEFAULT_SOURCE,
        ignoreViews: ignoreViews ? '1' : undefined,
        metadata: stringifiedMetadata,
    };

    if (featureToggles.otpVerification && userVariantName) {
        givenAttributes.personalisation = JSON.stringify({
            EXP: `sireve-mand-email-ver-1::${userVariantName}`,
        });
    }

    const mappedAttributes = mapKeys(givenAttributes, (value, propertyKey) =>
        ATTRIBUTES_MAP.get(propertyKey)
    );

    return (
        <div data-testid={dataTestId} {...mappedAttributes}>
            {children}
        </div>
    );
};

Track.propTypes = {
    children: PropTypes.node.isRequired,
    container: PropTypes.string,
    'data-testid': PropTypes.string,
    name: PropTypes.string,
    result: PropTypes.string,
    type: PropTypes.string,
    source: PropTypes.string,
    ignoreViews: PropTypes.bool,
    metadata: PropTypes.objectOf(PropTypes.string),
};

export default Track;
